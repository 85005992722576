<template>
  <div class="page">
    <div class="page-title">
      <img @click="router.back()" src="@/assets/images/back.svg">
      История операций
    </div>

    <div class="user-name">{{ address }}</div>

    <div v-if="actions" class="actions-list">
      <ECPaymentHistoryItem
        v-for="action in actions"
        :key="action.date_doc"
        :action="action"
      />
    </div>

    <div class="empty" v-if="actions !== null && actions.length === 0">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 14V28V34C6 36.6124 7.66962 38.8349 10 39.6586L10 42C10 43.1046 10.8954 44 12 44C13.1046 44 14 43.1046 14 42V40H34V42C34 43.1046 34.8954 44 36 44C37.1046 44 38 43.1046 38 42V39.6586C40.3304 38.8349 42 36.6124 42 34V10C42 6.68629 39.3137 4 36 4H12C8.68629 4 6 6.68629 6 10V14ZM38 34C38 35.1046 37.1046 36 36 36L16 36L16 28.0044L16 28L16 27.9956L16 14.0066L16 14L16 13.9934L16 8L36 8C37.1046 8 38 8.89543 38 10V34ZM10 16H12L12 26H10L10 16ZM12 30H10V34C10 35.1046 10.8954 36 12 36L12 30.2466V30ZM12 8C10.8954 8 10 8.89543 10 10V12H12L12 8ZM28 22C28 20.8954 28.8954 20 30 20C31.1046 20 32 20.8954 32 22C32 23.1046 31.1046 24 30 24C28.8954 24 28 23.1046 28 22ZM30 28C33.3137 28 36 25.3137 36 22C36 18.6863 33.3137 16 30 16C26.6863 16 24 18.6863 24 22C24 25.3137 26.6863 28 30 28Z" fill="#57BA5E"/>
      </svg>
      <span>По этому счету операций нет</span>
      <p>Тут будут отображаться все пополнения и списания</p>
    </div>

  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'
import useApi from '@/services/useApi'
import ECPaymentHistoryItem from './ECPaymentHistoryItem'

const route = useRoute()
const router = useRouter()

const actions = ref(null)
const address = ref('')

watch(() => route.params.bill, () => getHistory())

const getHistory = async() => {
  const req = await useApi('get', `bills/payment_history/${route.params.bill}`)

  if(req.address[0] && req.payment_history){
    address.value = req.address[0]
    actions.value = req.payment_history
  }
}

watch(()=> store.state.user.token, ()=> {
  if(!store.state.user.token){
    router.push('/')
  }
})

onMounted(() => {
  if(!store.state.user.token){
    router.push('/')
    return
  }
  getHistory()
})
</script>

<style scoped lang="scss">
.page-title{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img{
    margin-right: 10px;
    cursor: pointer;
  }
}
.user-name{
  color: #161616;
  opacity: 0.5;
  margin: 0 0 20px 52px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  position: relative;
  margin-bottom: 20px;
}
.action-item{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  &:last-child{margin-bottom: 0;}

  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    span{
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      opacity: 0.7;
      margin-right: 10px;
    }
  }
  &__status{
    position: relative;
    cursor: pointer;
    .hint{
      position: absolute;
      left: 0;
      top: 10px;
      padding: 8px 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: ease .3s
    }
    &:hover{
      .hint{
        opacity: 1;
        visibility: visible;
        top: 20px
      }
    }
  }
  &__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    >span{
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: #57BA5E;
    }
  }
}

.check{
  display: flex;
  padding: 8px 10px 8px 16px;
  background: $ec-grey;
  border-radius: 14px;
  align-items: center;
  span{
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
  }
}

.empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 200px;
  span{
    font-size: 22px;
    line-height: 22px;
    margin: 20px 0;
    text-align: center;
  }
  p{
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(22, 22, 22, 0.5);
  }
  @media (max-width: 1100px){
    margin-top: 105px;
  }
}
</style>
