<template>
  <div class="action-item">
    <div class="action-item__header">
      <span> {{action.Status === 2 ? 'Списание' : 'Пополнение'}} {{getDate(action.DateDoc)}}</span>
      <div v-if="action.Status !== 2" class="action-item__status">
        <img v-if="action.Status === 0" src="@/assets/images/status-success.svg">
        <img v-if="action.Status === 1" src="@/assets/images/status-wait.svg">
        <img v-if="action.Status === 3" src="@/assets/images/status-fail.svg">
        <img v-if="action.Status === 4" src="@/assets/images/status-side.svg">

        <div v-if="action.Description" class="hint">{{action.Description}}</div>
      </div>

    </div>
    <div class="action-item__body">
      <span :class="{red: action.Status === 2}">
        {{action.Status === 2 ? '-' : '+'}}  {{String(action.SumTotal)}} ₽
        <span class="additional-status" v-if="action.Assignment && action.Status === 2"> {{action.Assignment === 'Факт' ? 'По тарифу' : action.Assignment}} </span>
      </span>
      <a
        v-if="action.LinkToReceipt || action.OFDReceiptURL"
        class="check"
        :href="action.LinkToReceipt || action.OFDReceiptURL"
        target="_blank"
      >
        <span>{{action.Status === 2 ? 'Квитанция' : 'Чек'}}</span>
        <img src="@/assets/images/download-check.svg">
      </a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import moment from 'moment'

const props = defineProps({
  action: {
    type: Object,
    required: true,
  }
})

const getDate = (time) => moment(time).utc().format('DD.MM.YYYY ・ HH:mm')
</script>

<style scoped lang="scss">
.action-item{
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child{margin-bottom: 0;}

  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    span{
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
      opacity: 0.7;
      margin-right: 10px;
    }
  }
  &__status{
    position: relative;
    display: flex;
    cursor: pointer;
    .hint{
      position: absolute;
      left: 0;
      top: 10px;
      padding: 8px 16px;
      background: #FFFFFF;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: ease .3s;
      z-index: 2;
      @media (max-width: 740px){
        left: auto;
        right: 0px;
      }
      @media (max-width: 500px){
        font-size: 12px;
      }
    }
    &:hover{
      .hint{
        opacity: 1;
        visibility: visible;
        top: 16px
      }
    }
  }
  &__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    >span{
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: #57BA5E;
    }
    >a{
      @media (max-width: 1100px){
        span{
          display: none;
        }
      }
    }
  }
}

.check{
  display: flex;
  padding: 8px 10px 8px 16px;
  background: $ec-grey;
  border-radius: 14px;
  align-items: center;
  text-decoration: none;
  margin-right: -2px;
  span{
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
    color: $ford-medium-dark;
  }
  @media (max-width: 1100px){
    padding: 10px;
  }
}

.red{
  color: #FF6C6C !important;
  display: flex;
  align-items: center;
}

.additional-status{
  position: relative;
  margin-left: 25px;
  font-size: 16px;
  &:before{
    content:'';
    position: absolute;
    left: -13px;
    top: 0px;
    border-radius: 2px;
    height: 22px;
    width: 2px;
    background: rgba(21, 21, 21, 0.4);
  }
}
</style>
